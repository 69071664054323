/** @jsx jsx */
//import * as React from "react"
import { jsx } from "@emotion/react"
import { SEO } from "../../components/seo"
import LeadCapture from "../../components/leadCapture"

import { Link } from "gatsby"

import Page from "../../layouts/page"

const LIRServicesASNPage = () => {
  return <Page>
    <div>
      <div className="contain article page-body">
        <ul class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/articles">Articles</Link></li>
          <li><span>What is an ASN, and why would I need one?</span></li>
        </ul>

        <h1>What is an ASN, and why would I need one?</h1>

        <p>An autonomous system number (ASN) is a unique identifier for an organisation to identify themselves to other organisations on the internet.</p>

        <h2>What is an ASN?</h2>

        <p>
          An autonomous system, or AS is a number to identify a specific organisation or person's network with a unique external routing policy.
          To allow different organisations on the internet to interact with each other, each needs to have a unique identifier in the form of an ASN.
        </p>
        <p>
          An ASN can be either be in public or private space, however if you plan to connect to multiple organisations on the Internet, then you will need to obtain a public ASN.
        </p>

        <h2>Who assigns ASNs?</h2>

        <p>
          <a href="https://www.iana.org/">The Internet Assigned Numbers Authority</a> (IANA) is responsible for globally coordinating DNS root, IP addressing as well as other Internet protocol resources, including ASNs.
          IANA assigns ASNs to Regional Internet Registries.
        </p>
        <p>Regional Internet Registries (RIRs) are organisations that manage Internet number resources in specific regions of the world.</p>
        <p>There are five Regional Internet Registries across the world, these are:</p>
        <ul>
          <li><a href="https://www.afrinic.net/">African Network Information Center</a> (AFRINIC)</li>
          <li><a href="https://www.arin.net/">American Registry for Internet Numbers</a> (ARIN)</li>
          <li><a href="https://www.apnic.net/">Asia-Pacific Network Information Centre</a> (APNIC)</li>
          <li><a href="https://www.lacnic.net/">Latin American and Caribbean Network Information Centre</a> (LACNIC)</li>
          <li><a href="https://www.ripe.net/">Réseaux IP Européens Network Coordination Centre</a> (RIPE NCC)</li>
        </ul>
        <p>
          These five Regional Internet Registries are all united by the <a href="https://www.nro.net/">Number Resource Organisation</a>, an unincoporated organisation who's mission is to contribue to an open, stable and secure Internet by coordinating activites and projects between the different Regional Internet Registries, such as <a href="https://en.wikipedia.org/wiki/Resource_Public_Key_Infrastructure">Resource Public Key Infrastructure</a> (RPKI).
        </p>

        
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="What is an ASN, and why would I need one?"
    path="/articles/what-is-an-asn"
    article={true}
  />
)

export default LIRServicesASNPage
