/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"

const styles = css`
h1 {
  color: red;
}
.box {
  border: 1px solid #232323;
}
`

const LeadCapture = () => {
  return <section css={styles}>
    <div className="box">
      
    </div>
  </section>
}

export default LeadCapture